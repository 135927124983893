.color-picker {
    /* width: 100%; */
    text-align: center;
  }
  
  .hue-slider {
    -webkit-appearance: none;
    width: 300px;
    height: 5px;
    border-radius: 5px;
    background: linear-gradient(to right, red, yellow, lime, cyan, blue, magenta, red);
    outline: none;
    opacity: 0.9;
    transition: opacity 0.2s;
  }
  
  .hue-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .hue-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .brightness-slider {
    -webkit-appearance: none;
     width: 300px;
    height: 5px;
    border-radius: 5px;
    background: linear-gradient(to right, white, blue);
    outline: none;
    opacity: 0.9;
    transition: opacity 0.2s;
    margin-top: 10px;
  }
  
  .brightness-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .brightness-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .color-display {
    border: 1px solid black;
  }
  
  .grayscale-slider {
    -webkit-appearance: none;
    width: 300px;
    height: 5px;
    border-radius: 5px;
    background: white;
    outline: none;
    opacity: 0.9;
    transition: opacity 0.2s;
  }

  .grayscale-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }

  .grayscale-slide::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }

  /* Add these styles to support the new color picker */
  .hue-slider-vertical {
    -webkit-appearance: slider-vertical;
    appearance: slider-vertical;
    width: 20px;
    height: 260px;
    margin: 0;
    background: linear-gradient(to bottom, #ff0000, #ff9900, #ffff00, #00ff00, #00ffff, #0000ff, #9900ff, #ff00ff, #ff0000);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    outline: none;
  }

  .hue-slider-vertical::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 10px;
    border-radius: 10px;
    background: white;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,0.2);
  }

  .hue-slider-vertical::-moz-range-thumb {
    width: 24px;
    height: 10px;
    border-radius: 10px;
    background: white;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,0.2);
  }

  /* Add these styles for the vertical hue slider */
  input[type=range].hue-slider-vertical {
    background: linear-gradient(to bottom, #ff0000, #ff9900, #ffff00, #00ff00, #00ffff, #0000ff, #9900ff, #ff00ff, #ff0000) !important;
  }

  /* Removing these may help if they're causing conflicts */
  input[type=range].hue-slider-vertical::-webkit-slider-runnable-track {
    background: transparent !important;
  }

  input[type=range].hue-slider-vertical::-moz-range-track {
    background: transparent !important;
  }