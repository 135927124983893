.datepicker-container {
    padding: 20px;
    background-color: #000;
    color: #fff;
}

.react-datepicker {
    border: none;
    background-color: #000 !important;
}

.react-datepicker__month-container {
    background-color: #000;
    color: #fff;
}

.react-datepicker__header {
    background-color: #000;
    border-bottom: none;
    color: #fff;
}

.react-datepicker__current-month {
    color: #fff;
}

.react-datepicker__day-name, 
.react-datepicker__day {
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.react-datepicker__day:hover {
    background-color: #333;
}

.react-datepicker__day--selected, 
.react-datepicker__day--keyboard-selected {
    background-color: #fff;
    color: #ff1744;
    font-weight: bold;
}

.react-datepicker__day--outside-month {
    color: #555;
}

.react-datepicker__day--disabled {
    color: #999;
    background-color: #333;
    cursor: not-allowed;
}

.react-datepicker__navigation--previous {
    border-right-color: #fff;
}

.react-datepicker__navigation--next {
    border-left-color: #fff;
}
