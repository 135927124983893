@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CustomFont";
  src: url("/public/fonts/sequel-sans-roman-body.ttf") format("truetype");
  /* src: url('/public/fonts/sequel-sans-roman-disp.ttf') format('truetype'); */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: "CustomFont", sans-serif; */
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  /* font-family: sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  /* overflow: hidden; */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-scroll {
  overflow: hidden;
}

.scrollable-section {
  overflow-y: scroll;
}

.scrollable-section::-webkit-scrollbar {
  display: block;
  width: 2px;
  /* Adjust scrollbar width */
}

.scrollable-section::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 1);
  /* Customize scrollbar thumb */
  border-radius: 10px;
  /* Optional: rounded scrollbar thumb */
  width: 2px;
}

.scrollable-section::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  /* Customize scrollbar track */
  width: 2px;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.placeholder-custom::placeholder {
  color: rgba(255, 255, 255, 0.6);
  /* Customize the color and opacity here */
  opacity: 1;
  /* Ensures the placeholder is fully opaque in Safari */
}

/* Base notification styles */
.Toastify__toast-container {
  width: 320px;
}

.Toastify__toast {
  background: #000000 !important;
  color: #FFFFFF !important;
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Hide all icons */
.Toastify__toast-icon {
  display: none !important;
}

/* Progress bar */
.Toastify__progress-bar {
  background: rgba(255, 255, 255, 0.1);
  height: 1px;
}

/* Toast text */
.Toastify__toast-body {
  color: #FFFFFF !important;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

/* Remove default close button */
.Toastify__close-button {
  display: none;
}

/* Toast animations */
.Toastify__slide-enter {
  transform: translateY(-100%);
  opacity: 0;
}

.Toastify__slide-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 400ms cubic-bezier(0.16, 1, 0.3, 1);
}

.Toastify__slide-exit {
  transform: translateY(0);
  opacity: 1;
}

.Toastify__slide-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 800ms cubic-bezier(0.16, 1, 0.3, 1);
  /* Increased to 800ms with easing */
}

/* Gallery animations */
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fade-in {
  animation: fade-in 0.5s cubic-bezier(0.16, 1, 0.3, 1);
}

/* Enhanced luxury shuffle animations */
@keyframes shuffle-fade {
  0% {
    opacity: 0;
    transform: scale(1.02) translateY(10px);
    filter: blur(10px);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.01) translateY(5px);
    filter: blur(5px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
    filter: blur(0);
  }
}

@keyframes shuffle-scale {
  0% {
    opacity: 0;
    transform: scale(1.05);
  }

  50% {
    opacity: 0.7;
    transform: scale(0.98);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shuffle-slide {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.gallery-image-enter {
  animation: shuffle-fade 0.8s cubic-bezier(0.22, 1, 0.36, 1) forwards;
}

.gallery-image-exit {
  animation: shuffle-fade 0.8s cubic-bezier(0.22, 1, 0.36, 1) reverse forwards;
}

/* Staggered animation for multiple images */
.gallery-grid>div:nth-child(1) {
  animation-delay: 0s;
}

.gallery-grid>div:nth-child(2) {
  animation-delay: 0.05s;
}

.gallery-grid>div:nth-child(3) {
  animation-delay: 0.1s;
}

.gallery-grid>div:nth-child(4) {
  animation-delay: 0.15s;
}

.gallery-grid>div:nth-child(5) {
  animation-delay: 0.2s;
}

.gallery-grid>div:nth-child(6) {
  animation-delay: 0.25s;
}

/* Hover effects */
.gallery-image {
  transition: transform 0.8s cubic-bezier(0.22, 1, 0.36, 1),
    opacity 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

.gallery-image:hover {
  transform: scale(1.02);
}

/* Smooth position transitions */
.gallery-container>div {
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

/* Random shuffle variations */
.shuffle-variant-1 {
  animation: shuffle-fade 0.8s cubic-bezier(0.22, 1, 0.36, 1) forwards;
}

.shuffle-variant-2 {
  animation: shuffle-scale 0.8s cubic-bezier(0.22, 1, 0.36, 1) forwards;
}

.shuffle-variant-3 {
  animation: shuffle-slide 0.8s cubic-bezier(0.22, 1, 0.36, 1) forwards;
}

/* Remove previous gallery animations and replace with these */
@keyframes shuffle-move {
  0% {
    transform: scale(0.99);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes shuffle-slide-up {
  0% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes shuffle-slide-down {
  0% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0);
  }
}

/* Smooth position transitions */
.gallery-image {
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

/* Random shuffle variations - more subtle */
.shuffle-variant-1 {
  animation: shuffle-move 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.shuffle-variant-2 {
  animation: shuffle-slide-up 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.shuffle-variant-3 {
  animation: shuffle-slide-down 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Staggered animation for multiple images */
.gallery-grid>div:nth-child(1) {
  animation-delay: 0s;
}

.gallery-grid>div:nth-child(2) {
  animation-delay: 0.05s;
}

.gallery-grid>div:nth-child(3) {
  animation-delay: 0.1s;
}

.gallery-grid>div:nth-child(4) {
  animation-delay: 0.15s;
}

.gallery-grid>div:nth-child(5) {
  animation-delay: 0.2s;
}

.gallery-grid>div:nth-child(6) {
  animation-delay: 0.25s;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}